import { configureStore } from "@reduxjs/toolkit";
import GuideReducer from "./features/GuideSlice";
import NavBarRuducer from "./features/NavBarSlice";
import GalleryRuducer from "./features/GallerySlice";
import LanguageReducer from "./features/LanguageSlice";
import sharedLayoutReducer from "./features/SharedLayoutSlice";
import responsiveReducer from "./features/ResponsiveSlice";
import utilitiesReducer from './features/UtilitiesSlice'
import PlanRuducer from './features/PlanSlice'

const store = configureStore({
  reducer: {
    guide: GuideReducer,
    nav: NavBarRuducer,
    plan: PlanRuducer,
    gallery: GalleryRuducer,
    language: LanguageReducer,
    sharedLayout: sharedLayoutReducer,
    responsive: responsiveReducer,
    utilities: utilitiesReducer,
  },
});

export default store;
