import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    houseSampleViewTitle: "",
    activeHouseSampleView: false,
  };

const planSlice = createSlice({
  name: "plan",
  initialState,
  reducers: {
    setHouseSampleViewTitle: (state, action) => {
      state.houseSampleViewTitle = action.payload;
    },
    setActiveHouseSampleView: (state, action) => {
        state.activeHouseSampleView = action.payload;
      },
  },
});

export default planSlice.reducer;
export const { setHouseSampleViewTitle, setActiveHouseSampleView } = planSlice.actions;
