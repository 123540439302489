import React, { Suspense, useState } from "react";
import "./App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { IntlProvider } from "react-intl";

const Loading = React.lazy(() => import("./pages/Loading/Loading"));
const Verify = React.lazy(() => import("./pages/Verify/Verify"));
const Error = React.lazy(() => import("./pages/Error/Error"));
const SharedLayout = React.lazy(() =>
  import("./pages/SharedLayout/SharedLayout")
);
// TỔNG THỂ
const Tour = React.lazy(() => import("./pages/Tour/Tour"));
const Location = React.lazy(() => import("./pages/Location/Location"));
const Subdivision = React.lazy(() => import("./pages/Subdivision/Subdivision"));
const Gallery = React.lazy(() => import("./pages/Gallery/Gallery"));
const Policy = React.lazy(() => import("./pages/Policy/Policy"));
// CENTRAL PARK RESIDENCES
const TourCentralPark = React.lazy(() =>
  import("./pages/Tour/TourCentralPark")
);
const LocationCentralPark = React.lazy(() =>
  import("./pages/Location/LocationCentralPark")
);
const PlanCentralPark = React.lazy(() => import("./pages/Plan/Plan"));
const HouseCentralPark = React.lazy(() => import("./pages/House/House"));
const UtilsCentralPark = React.lazy(() =>
  import("./pages/Utilities/Utilities")
);
const EBrochureCentralPark = React.lazy(() =>
  import("./pages/EBrochure/EBrochureCentralPark")
);
// THE CAMPUS
const TourTheCampus = React.lazy(() =>
  import("./pages/Tour/TourTheCampus")
);
const LocationTheCampus = React.lazy(() =>
  import("./pages/Location/LocationTheCampus")
);
const PlanTheCampus = React.lazy(() => import("./pages/Plan/PlanTheCampus"));
const ArchitectureTheCampus = React.lazy(() => import("./pages/Architecture/ArchitectureTheCampus"));
const UtilsTheCampus = React.lazy(() =>
  import("./pages/Utilities/UtilitiesTheCampus")
);
const EBrochureTheCampus = React.lazy(() =>
  import("./pages/EBrochure/EBrochureTheCampus")
);
// SEAVIEW RESIDENCES
const TourSeaview = React.lazy(() =>
  import("./pages/Tour/TourSeaview")
);
const LocationSeaview = React.lazy(() =>
  import("./pages/Location/LocationSeaview")
);
const PlanSeaview = React.lazy(() => import("./pages/Plan/PlanSeaview"));
const UtilsSeaview = React.lazy(() =>
  import("./pages/Utilities/UtilitiesSeaview")
);
const HouseSeaview = React.lazy(() => import("./pages/House/HouseSeaview"));
const EBrochureSeaview = React.lazy(() =>
  import("./pages/EBrochure/EBrochureSeaview")
);
// CENTRAL ISLAND
const HouseCentralIsland = React.lazy(() => import("./pages/House/HouseCentralIsland"));
const EBrochureCentralIsland = React.lazy(() =>
  import("./pages/EBrochure/EBrochureCentralIsland")
);

// CENTRAL PLAZA
const TourCentralPlaza = React.lazy(() =>
  import("./pages/Tour/TourCentralPlaza")
);
const LocationCentralPlaza = React.lazy(() =>
  import("./pages/Location/LocationCentralPlaza")
);
const PlanCentralPlaza = React.lazy(() => import("./pages/Plan/PlanCentralPlaza"));
const UtilsCentralPlaza = React.lazy(() =>
  import("./pages/Utilities/UtilitiesCentralPlaza")
);
const ArchitectureCentralPlaza = React.lazy(() => import("./pages/Architecture/ArchitectureCentralPlaza"));
const EBrochureCentralPlaza = React.lazy(() =>
  import("./pages/EBrochure/EBrochureCentralPlaza")
);

function App() {
  const languageState = useSelector((state) => state.language);
  const { locale, messages } = languageState;
  const [checkVerify, setCheckVerify] = useState(true);

  return (
    <IntlProvider messages={messages} locale={locale}>
      <div className="App">
        <Suspense fallback={<div>Loading...</div>}>
          <Router>
            {checkVerify === true ? (
              <Routes>
                <Route path="/" index element={<Loading />} />
                <Route element={<SharedLayout />}>
                  {/* <Route
                    index
                    path="/verify"
                    element={<Verify setCheckVerify={setCheckVerify} />}
                  /> */}
                  <Route index path="/tour" element={<Tour />} />
                  <Route index path="/location" element={<Location />} />
                  <Route index path="/subdivision" element={<Subdivision />} />
                  <Route index path="/gallery" element={<Gallery />} />

                  <Route
                    index
                    path="/centralparkresidences/tour"
                    element={<TourCentralPark />}
                  />
                  <Route
                    index
                    path="/centralparkresidences/plan"
                    element={<PlanCentralPark />}
                  />
                  <Route
                    index
                    path="/centralparkresidences/house"
                    element={<HouseCentralPark />}
                  />
                  <Route
                    index
                    path="/centralparkresidences/utils"
                    element={<UtilsCentralPark />}
                  />
                  <Route
                    index
                    path="/centralparkresidences/location"
                    element={<LocationCentralPark />}
                  />
                  <Route
                    index
                    path="/centralparkresidences/ebrochure"
                    element={<EBrochureCentralPark />}
                  />
                  {/* THE CAMPUS */}
                  <Route
                    index
                    path="/thecampus/tour"
                    element={<TourTheCampus />}
                  />
                  <Route
                    index
                    path="/thecampus/plan"
                    element={<PlanTheCampus />}
                  />
                   <Route
                    index
                    path="/thecampus/architecture"
                    element={<ArchitectureTheCampus />}
                  />
                  <Route
                    index
                    path="/thecampus/utils"
                    element={<UtilsTheCampus />}
                  />
                  <Route
                    index
                    path="/thecampus/location"
                    element={<LocationTheCampus />}
                  />
                  <Route
                    index
                    path="/thecampus/ebrochure"
                    element={<EBrochureTheCampus />}
                  />
                  {/* SEAVIEW RESIDENCES */}
                  <Route
                    index
                    path="/seaviewresidences/tour"
                    element={<TourSeaview />}
                  />
                  <Route
                    index
                    path="/seaviewresidences/location"
                    element={<LocationSeaview />}
                  />
                  <Route
                    index
                    path="/seaviewresidences/plan"
                    element={<PlanSeaview />}
                  />
                  <Route
                    index
                    path="/seaviewresidences/utils"
                    element={<UtilsSeaview />}
                  />
                  <Route
                    index
                    path="/seaviewresidences/house"
                    element={<HouseSeaview />}
                  />
                  <Route
                    index
                    path="/seaviewresidences/ebrochure"
                    element={<EBrochureSeaview />}
                  />

                  <Route
                    index
                    path="/chinh-sach-bao-mat"
                    element={<Policy />}
                  />
                  {/* CENTRAL ISLAND */}
                  <Route
                    index
                    path="/centralisland/house"
                    element={<HouseCentralIsland />}
                  />
                  <Route
                    index
                    path="/centralisland/ebrochure"
                    element={<EBrochureCentralIsland />}
                  />
                  {/* CENTRAL PLAZA */}
                  <Route
                    index
                    path="/centralplaza/tour"
                    element={<TourCentralPlaza />}
                  />
                  <Route
                    index
                    path="/centralplaza/location"
                    element={<LocationCentralPlaza />}
                  />
                  <Route
                    index
                    path="/centralplaza/plan"
                    element={<PlanCentralPlaza />}
                  />
                  <Route
                    index
                    path="/centralplaza/architecture"
                    element={<ArchitectureCentralPlaza />}
                  />
                  <Route
                    index
                    path="/centralplaza/utils"
                    element={<UtilsCentralPlaza />}
                  />
                  <Route
                    index
                    path="/centralplaza/ebrochure"
                    element={<EBrochureCentralPlaza />}
                  />
                  
                  


                </Route>
                <Route path="*" element={<Error />} />
              </Routes>
            ) : (
              <Routes>
                <Route
                  path="*"
                  element={<Verify setCheckVerify={setCheckVerify} />}
                />
              </Routes>
            )}
            {/* <Routes>
              <Route path={"/"} index element={<Loading />} />
              <Route element={<SharedLayout />}>
                <Route index path="/verify" element={<Verify setCheckVerify={setCheckVerify} />} />
                <Route index path="/tour" element={<Tour />} />
                <Route index path="/location" element={<Location />} />
                <Route index path="/subdivision" element={<Subdivision />} />
                <Route index path="/gallery" element={<Gallery />} />

                <Route index path="/centralparkresidences/plan" element={<PlanCentralPark />} />
                <Route index path="/centralparkresidences/house" element={<HouseCentralPark />} />
                <Route index path="/centralparkresidences/utils" element={<UtilsCentralPark />} />
                <Route index path="/centralparkresidences/location" element={<LocationCentralPark />} />

                <Route index path="/thecampus/plan" element={<PlanTheCampus />} />
                <Route index path="/thecampus/utils" element={<UtilsTheCampus />} />
                <Route index path="/thecampus/location" element={<LocationTheCampus />} />
                <Route index path="/thecampus/ebrochure" element={<EBrochureTheCampus />} />

                <Route index path="/chinh-sach-bao-mat" element={<Policy />} />
              </Route>
              <Route path="*" element={<Error />} />
            </Routes> */}
          </Router>
        </Suspense>
      </div>
    </IntlProvider>
  );
}

export default App;
