import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  utilsTheCampusActiveNumber: 1,
  utilsThecampusActiveSubmenuNumber: 0,
  utilsSeaviewActiveNumber: 1,
  utilsSeaviewActiveSubmenuNumber: 0,
  utilsTheCanpusActiveNumberList: [
    {
      id: 1,
      text: "Công Viên Khoa Học",
      scene: "../file360/thecampus_tien_ich/index.html?startscene=scene_ti_3",
      submenu: [
        {
          id: 1,
          text: "Thám Hiểm Trong Rừng",
          scene: "../file360/thecampus_tien_ich/index.html?startscene=scene_ti_3",
        },
        {
          id: 2,
          text: "Lớp Học Trong Rừng",
          scene: "../file360/thecampus_tien_ich/index.html?startscene=scene_ti_2",
        },
        {
          id: 3,
          text: "Cắm Trại Trong Rừng",
          scene: "../file360/thecampus_tien_ich/index.html?startscene=scene_ti_5",
        },
      ],
    },
    {
      id: 2,
      text: "Công Viên Nhiệt Đới",
      scene: "../file360/thecampus_tien_ich/index.html?startscene=scene_ti_1",
    },
    {
      id: 3,
      text: "Công Viên Sáng Tạo",
      scene: "../file360/thecampus_tien_ich/index.html?startscene=scene_ti_11",
    },
    {
      id: 4,
      text: "Kid Playground",
      scene: "../file360/thecampus_tien_ich/index.html?startscene=scene_ti_6",
    },
    {
      id: 5,
      text: "Công Viên Thể Thao",
      scene: "../file360/thecampus_tien_ich/index.html?startscene=scene_ti_7",
      submenu: [
        {
          id: 4,
          text: "Trò Chơi Liên Hoàn",
          scene: "../file360/thecampus_tien_ich/index.html?startscene=scene_ti_7",
        },
        {
          id: 5,
          text: "Khu Leo Núi",
          scene: "../file360/thecampus_tien_ich/index.html?startscene=scene_ti_8",
        },
        {
          id: 6,
          text: "Sân Thể Thao",
          scene: "../file360/thecampus_tien_ich/index.html?startscene=scene_kt_2",
        },
      ],
    },
    {
      id: 6,
      text: "Khu Vườn Của Bé",
      scene: "../file360/thecampus_tien_ich/index.html?startscene=scene_ti_10",
    },
    {
      id: 7,
      text: "Nông Trại Vui Vẻ",
      scene: "../file360/thecampus_tien_ich/index.html?startscene=scene_ti_9",
    },
    {
      id: 8,
      text: "Không Gian Học Tập",
      scene: "../file360/thecampus_tien_ich/index.html?startscene=scene_kt_4",
    },
    {
      id: 9,
      text: "Hẻm Dân Gian",
      scene: "../file360/thecampus_tien_ich/index.html?startscene=scene_ti_12",
    },
    {
      id: 10,
      text: "Trường Học",
      scene: "../file360/thecampus_tien_ich/index.html?startscene=scene_ti_13",
    },
    
  ],
  utilsSeaviewActiveNumberList: [
    {
      id: 1,
      text: "Sảnh Đón",
      scene: "../file360/seaview_tienich/index.html?startscene=scene_1_lobbyngoaithat",
      submenu: [
        {
          id: 1,
          text: "Lobby Ngoại Thất",
          scene: "../file360/seaview_tienich/index.html?startscene=scene_1_lobbyngoaithat",
        },
        {
          id: 2,
          text: "Sảnh Lễ Tân",
          scene: "../file360/seaview_tienich/index.html?startscene=scene_2_sanhdoncudan",
        },
        {
          id: 3,
          text: "Hành Lang Căn Hộ",
          scene: "../file360/seaview_tienich/index.html?startscene=scene_13_sanhhanglangcanho",
        },
      ],
    },
    {
      id: 2,
      text: "Quảng Trường Nhiệt Đới",
      scene: "../file360/seaview_tienich/index.html?startscene=scene_5_quangtruongnhietdoi",
    },
    {
      id: 3,
      text: "Vườn Dã Ngoại",
      scene: "../file360/seaview_tienich/index.html?startscene=scene_5_vuondangoai",
    },
    {
      id: 4,
      text: "Vườn Gym",
      scene: "../file360/seaview_tienich/index.html?startscene=scene_7_vuongym",
    },
    {
      id: 5,
      text: "Vườn Cờ",
      scene: "../file360/seaview_tienich/index.html?startscene=scene_8_vuonco",
    },
    {
      id: 6,
      text: "Suối Sương Mai",
      scene: "../file360/seaview_tienich/index.html?startscene=scene_6_suoisuongmai",
    },
    {
      id: 7,
      text: "Quảng Trường Kết Nối",
      scene: "../file360/seaview_tienich/index.html?startscene=scene_9_quangtruongkeetnoi",
    },
    {
      id: 8,
      text: "Vườn Sức Khoẻ",
      scene: "../file360/seaview_tienich/index.html?startscene=scene_11_vuonsuckhoe",
    },
    {
      id: 9,
      text: "Sân Chơi Trẻ Em",
      scene: "../file360/seaview_tienich/index.html?startscene=scene_10_khuvuichoitreem",
    },
    {
      id: 10,
      text: "Hồ Bơi",
      scene: "../file360/seaview_tienich/index.html?startscene=scene_12_hoboi",
    },
    
  ],

  utilsCentralPlazaActiveNumberList: [
    {
      id: 1,
      text: "Công Viên Trung Tâm",
      scene: "../file360/centralplaza_tien_ich/index.html?startscene=scene_cong_vien_trung_tam",
    },
    {
      id: 2,
      text: "Quảng Trường",
      scene: "../file360/centralplaza_tien_ich/index.html?startscene=scene_quang_truong_ngay",
    },
    {
      id: 3,
      text: "Tháp Kim Cương",
      scene: "../file360/centralplaza_tien_ich/index.html?startscene=scene_thap_kim_cuong",
    },
    {
      id: 4,
      text: "Trung Tâm Thương Mại",
      scene: "../file360/centralplaza_tien_ich/index.html?startscene=scene_trung_tam_thuong_mai",
    },
    {
      id: 5,
      text: "Lobby Căn Hộ",
      scene: "../file360/centralplaza_tien_ich/index.html?startscene=scene_lobby",
    },
    {
      id: 6,
      text: "Sảnh Thang Máy",
      scene: "../file360/centralplaza_tien_ich/index.html?startscene=scene_sanh_thang_may",
    },
    {
      id: 7,
      text: "Hành Lang Căn Hộ",
      scene: "../file360/centralplaza_tien_ich/index.html?startscene=scene_hanh_lang_can_ho",
    },
    {
      id: 8,
      text: "Hồ Bơi",
      scene: "../file360/centralplaza_tien_ich/index.html?startscene=scene_ho_boi_ngay",
    },
    {
      id: 9,
      text: "Vườn Thượng Uyển",
      scene: "../file360/centralplaza_tien_ich/index.html?startscene=scene_vuon_bbq",
    },
    {
      id: 10,
      text: "Plaza",
      scene: "../file360/centralplaza_tien_ich/index.html?startscene=scene_plaza1",
      submenu: [
        {
          id: 1,
          text: "Plaza 1",
          scene: "../file360/centralplaza_tien_ich/index.html?startscene=scene_plaza1",
        },
        {
          id: 2,
          text: "Plaza 2",
          scene: "../file360/centralplaza_tien_ich/index.html?startscene=scene_plaza2",
        },
        {
          id: 3,
          text: "Plaza 3",
          scene: "../file360/centralplaza_tien_ich/index.html?startscene=scene_plaza3",
        },
      ],
    },
    
  ],
};

const utilitiesSlice = createSlice({
  name: "utilities",
  initialState,
  reducers: {
    setUtilsTheCampusActiveNumber: (state, action) => {
      state.utilsTheCampusActiveNumber = action.payload;
    },
    setUtilsThecampusActiveSubmenuNumber: (state, action) => {
      state.utilsThecampusActiveSubmenuNumber = action.payload;
    },
    setUtilsSeaviewActiveNumber: (state, action) => {
      state.utilsSeaviewActiveNumber = action.payload;
    },
    setUtilsSeaviewActiveSubmenuNumber: (state, action) => {
      state.utilsSeaviewActiveSubmenuNumber = action.payload;
    },
  },
});

export default utilitiesSlice.reducer;
export const {
  setUtilsTheCampusActiveNumber,
  setUtilsThecampusActiveSubmenuNumber,
  setUtilsSeaviewActiveNumber,
  setUtilsSeaviewActiveSubmenuNumber,
} = utilitiesSlice.actions;
